/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { coursePurchaseSelector as stateSelector } from 'reduxModules/tutorials/selectors'
import math from 'reduxModules/utils/math'
import AddToCartButton from '../../generic/AddToCartButton'
import keyUpHandler from '../../utils/keyUpHandler'

import './styles.css'

export const naturalLanguageMapping = value => {
  switch (value) {
    case 'DVD-9':
    case 'DVD-5':
      return 'DVD'
    case 'BD-25':
    case 'BD-50':
      return 'Blu-ray'
    default:
      return value
  }
}

export class CoursePurchase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comparing: false,
      activeSelector: null,
      activeCategory: null,
      dvd: 0,
      stream: 0,
      subscriptions: 0
    }

    this.setup = this.setup.bind(this)

    this.getActiveCategory = this.getActiveCategory.bind(this)
    this.setActiveCategory = this.setActiveCategory.bind(this)

    this.getSelectedOption = this.getSelectedOption.bind(this)
    this.setActiveOption = this.setActiveOption.bind(this)

    this.toggleCompare = this.toggleCompare.bind(this)
    this.toggleSelector = this.toggleSelector.bind(this)

    this.optionActive = (category, option) =>
      this.categoryActive(category) && this.state[category.name] === option
    this.categoryActive = category =>
      category.name === this.state.activeCategory
    this.selectorActive = category =>
      category.name === this.state.activeSelector
    this.isMultiple = category => category.selector.length > 1
  }

  componentDidMount() {
    if (this.props.categories.length && !this.state.activeOption)
      this.setup(this.props.categories)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.categories, this.props.categories) ||
      (nextProps.categories && !this.state.activeCategory)
    )
      this.setup(nextProps.categories)
  }

  setup(categories) {
    let activeCategory = this.state.activeCategory
    const status = categories.reduce((acum, category) => {
      if (!activeCategory || category.default) activeCategory = category.name
      return { ...acum, [category.name]: 0 }
    }, {})
    this.setState({ ...status, activeCategory })
  }

  toggleCompare() {
    this.setState({ comparing: !this.state.comparing })
  }

  toggleSelector(event, category) {
    event.stopPropagation()
    this.setState({
      activeSelector:
        this.state.activeSelector === category.name ? null : category.name
    })
  }

  getSelectedOption(category) {
    const index = this.state[category.name] || 0
    return category.selector[index]
  }

  setActiveCategory(category) {
    return this.setState({
      activeSelector: null,
      activeCategory: category.name
    })
  }

  setActiveOption(category, option = 0) {
    this.setState({
      activeSelector: null,
      activeCategory: category.name,
      [category.name]: option
    })
  }

  getActiveCategory() {
    const categoryName = this.state.activeCategory
    return _.find(this.props.categories, { name: categoryName })
  }

  getActiveOption() {
    const category = this.getActiveCategory()
    if (category) {
      const index = this.state[category.name]
      return category.selector[index]
    }
    return null
  }

  render() {
    const props = this.props
    const activeCategory = this.getActiveCategory()
    const activeOption = this.getActiveOption()
    const isYearlySubscription =
      props.currentSubscription && props.currentSubscription === 'Yearly'
    const isGroupSubscriber = props.isGroupSubscriber
    const isAnnualSubscriber = props.isAnnualSubscriber
    const isFreeWebinarForYearlies = category =>
      (isYearlySubscription || isGroupSubscriber || isAnnualSubscriber) &&
      !category.recorded &&
      this.props.isWebinar
    const currentNaturalOption = naturalLanguageMapping(
      this.state.activeCategory
    )

    if (!this.props.categories.length || this.props.isLibrarySubscriber)
      return null
    return (
      <section
        id="course-purchase"
        className={`${this.state.comparing ? '-comparing' : ''} ${
          this.props.onSide ? 'on-side' : ''
        } ${this.props.onSide ? 'wrapper-1080' : ''} ${
          this.props.isSimpleSection ? '-simple-section' : ''
        }`}
      >
        <div className="container">
          {this.props.isSubscribed || this.props.isOwned ? (
            <div className="headline">
              <h3>you can also get</h3>
            </div>
          ) : null}
          <div className="wrapper-1080">
            {this.props.categories.map(category => {
              const value = this.getSelectedOption(category)
              const hasDiscount =
                value.regularPrice &&
                value.regularPrice !== value.price &&
                value.price > 0
              const categoryName =
                category?.name?.startsWith('DVD') ||
                category?.name?.startsWith('BD')
                  ? 'dvd'
                  : category.name
              return (
                <div
                  style={{ order: category.order }}
                  className={`${categoryName} ${
                    this.selectorActive(category) ? '-open' : ''
                  } ${this.isMultiple(category) ? '-multiple' : ''}`}
                  key={`${value.name}-${value.price}`}
                >
                  <button
                    type="button"
                    className={`purchase-option ${categoryName} ${
                      this.state.activeCategory === 'subscriptions' &&
                      this.state.subscriptions === 2 &&
                      categoryName === 'subscriptions'
                        ? '-best'
                        : ''
                    } ${this.categoryActive(category) ? '-active' : ''} ${
                      this.props.isSimpleSection ? '-simple-section' : ''
                    }`}
                    aria-label="Select purchase option"
                    onClick={() => this.setActiveCategory(category)}
                    onDoubleClick={e => this.toggleSelector(e, category)}
                    onKeyUp={event =>
                      keyUpHandler(event, this.setActiveCategory, null, this, [
                        category
                      ])
                    }
                  >
                    <div className="name">
                      <i className={`icon ${value.icon}`} />
                      <div className="group-text">
                        <div>{naturalLanguageMapping(value.name)}</div>
                        <div className="price">
                          {hasDiscount ? (
                            <span className="price-before-discount">
                              ${math.toFixed(value.regularPrice)}
                            </span>
                          ) : null}
                          {(((!isYearlySubscription ||
                            !isGroupSubscriber ||
                            !isAnnualSubscriber) &&
                            props.isWebinar) ||
                            category.recorded ||
                            props.isTutorial) &&
                          !isFreeWebinarForYearlies(category)
                            ? typeof value.price === 'number' && value.price > 0
                              ? `$${math.toFixed(value.price)}`
                              : `$${math.toFixed(value.regularPrice)}`
                            : null}
                          {isFreeWebinarForYearlies(category) ? (
                            <>FREE</>
                          ) : null}
                        </div>
                      </div>
                      {this.isMultiple(category) ? (
                        <button
                          type="button"
                          onClick={e => this.toggleSelector(e, category)}
                        >
                          <i className="open icon-down" />
                        </button>
                      ) : null}
                    </div>
                    <div
                      className="details"
                      style={{
                        display: _.isArray(value.details) ? 'block' : 'none'
                      }}
                    >
                      <ul>
                        {_.isArray(value.details)
                          ? value.details.map(item => (
                              <li
                                key={item}
                                dangerouslySetInnerHTML={{ __html: item }}
                              />
                            ))
                          : null}
                      </ul>
                    </div>
                    <div
                      className="details"
                      style={{
                        display: _.isString(value.details) ? 'block' : 'none'
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: value.details }}
                      />
                    </div>
                  </button>
                  {this.isMultiple(category) ? (
                    <div className="selector">
                      {category.selector.map((option, optionIndex) => (
                        <button
                          type="button"
                          className={`purchase-option ${
                            optionIndex === 2 ? '-best' : ''
                          } ${props.isSimpleSection ? '-simple-section' : ''}`}
                          onClick={() =>
                            this.setActiveOption(category, optionIndex)
                          }
                          key={option.name}
                        >
                          <div className="name">
                            <i className={`icon ${option.icon}`} />
                            <span>
                              <div>{option.name}</div>
                              <div className="price">
                                {typeof option.price === 'number'
                                  ? `$${math.toFixed(option.price)}`
                                  : option.price}
                              </div>
                            </span>
                            <i className="open icon-down" />
                          </div>
                        </button>
                      ))}
                    </div>
                  ) : null}
                </div>
              )
            })}
            <div
              className="compare"
              style={{
                flexBasis: props.isMobile
                  ? '100%'
                  : `${25 * props.categories.length}%`
              }}
            >
              <button onClick={this.toggleCompare} type="button">
                {!this.state.comparing && props.categories.length > 1
                  ? 'Compare Options'
                  : null}
                {!this.state.comparing && props.categories.length === 1
                  ? 'Show More'
                  : null}
                {this.state.comparing ? 'Show Less' : null}
              </button>
            </div>
          </div>
          {(currentNaturalOption === 'DVD' ||
            currentNaturalOption === 'Blu-ray') && (
            <div className="no-cc-notice">
              Notice: {currentNaturalOption}s do not include closed captions.
            </div>
          )}
          {!props.isMobile ? (
            <div className="action">
              {activeCategory && activeOption ? (
                <>
                  {activeCategory.name !== 'subscriptions' &&
                    !(
                      activeCategory.type === 'live' &&
                      isYearlySubscription &&
                      !activeCategory.recorded
                    ) && (
                      <AddToCartButton
                        type={props.isSimpleSection ? 'secondary' : 'main'}
                        url={activeOption.url}
                      >
                        <span>{activeCategory.cta}</span>
                      </AddToCartButton>
                    )}
                  {!(
                    activeCategory.type !== 'live' ||
                    !isYearlySubscription ||
                    activeCategory.recorded
                  ) && (
                    <button
                      type="button"
                      className="add-to-cart-btn add-to-cart-btn--main"
                      onClick={() => props.webinarEnroll(activeOption.id)}
                    >
                      {activeCategory.cta}
                    </button>
                  )}
                  {activeCategory.name === 'subscriptions' && (
                    <>
                      <button
                        type="button"
                        className="add-to-cart-btn"
                        onClick={() => props.selectPlan(activeOption.plan)}
                      >
                        Subscribe
                      </button>
                      <span>Free 7-Day Trial</span>
                    </>
                  )}
                </>
              ) : null}
            </div>
          ) : null}

          {props.isMobile ? (
            <div className="mobile-action">
              {activeCategory && activeOption ? (
                <>
                  {activeCategory.name !== 'subscriptions' && (
                    <AddToCartButton url={activeOption.url}>
                      <span>{activeCategory.cta}</span>
                    </AddToCartButton>
                  )}
                  {activeCategory.name === 'subscriptions' && (
                    <>
                      <button
                        type="button"
                        className="add-to-cart-btn"
                        onClick={() => props.selectPlan(activeOption.plan)}
                      >
                        Subscribe
                      </button>
                      <span>Free 7-Day Trial</span>
                    </>
                  )}
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      </section>
    )
  }
}

const purchaseOptionPropTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  best: PropTypes.bool
}

CoursePurchase.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      order: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      cta: PropTypes.string.isRequired,
      selector: PropTypes.arrayOf(PropTypes.shape(purchaseOptionPropTypes))
        .isRequired
    })
  ),
  selectPlan: PropTypes.func,
  currentSubscription: PropTypes.string,
  webinarEnroll: PropTypes.func,
  isSubscribed: PropTypes.bool,
  isOwned: PropTypes.bool,
  isMobile: PropTypes.bool,
  isLibrarySubscriber: PropTypes.bool
}

CoursePurchase.defaultProps = {
  isSubscribed: false,
  isOwned: false,
  isMobile: false,
  isLibrarySubscriber: false
}

export default connect(stateSelector, null)(CoursePurchase)
