import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Helmet } from 'react-helmet'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/school/selectors'
import { schoolDispatchers as dispatchers } from 'reduxModules/school/dispatchers'

import VideoModal from '../generic/VideoModal'
import Teacher from '../generic/ProfileSummary'
import TestimonialVideo from '../generic/TestimonialVideos'
import keyUpHandler from '../utils/keyUpHandler'

import SchoolBannerMobile from '../../global/assets/images/school/school-new-mobile.jpg'
import SchoolBannerDesktop from '../../global/assets/images/school/school-new-desktop.jpg'
import SchoolOurHistoryDesktop from '../../global/assets/images/school/school-our-history.png'
import SchoolOurHistoryMobile from '../../global/assets/images/school/school-our-history-mobile.png'

import './styles.css'

const videos = {
  school: {
    video:
      'https://d35pwikzwzmbep.cloudfront.net/videos/about/2021-BRAND-VIDEO-2min-a-2021-BRAND-VIDEO-2min-a-1280x720-1200kbps-music.mp4',
    subtitles: [
      {
        file:
          'https://d35pwikzwzmbep.cloudfront.net/videos/about/2021-BRAND-VIDEO-2min-a-2021-BRAND-VIDEO-2min-a-1280x720-1200kbps-music-en.vtt',
        label: 'EN'
      },
      {
        file:
          'https://d35pwikzwzmbep.cloudfront.net/videos/about/2021-BRAND-VIDEO-2min-a-2021-BRAND-VIDEO-2min-a-1280x720-1200kbps-music-es.vtt',
        label: 'ES'
      }
    ]
  },
  history: {
    video:
      'https://d35pwikzwzmbep.cloudfront.net/videos/about/Stan_Winston_Studio_Promo_Video.mp4',
    subtitles: [
      {
        file:
          'https://d35pwikzwzmbep.cloudfront.net/videos/about/Stan_Winston_Studio_Promo_Video-en.vtt',
        label: 'EN'
      },
      {
        file:
          'https://d35pwikzwzmbep.cloudfront.net/videos/about/Stan_Winston_Studio_Promo_Video-es.vtt',
        label: 'ES'
      }
    ]
  }
}

export function School({ loadRandomArtists, loadRandomTestimonial, artists }) {
  const [showVideo, setShowVideo] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const videoToDisplay = currentTab === 0 ? videos.school : videos.history

  useEffect(() => {
    loadRandomArtists()
  }, [loadRandomArtists, loadRandomTestimonial])

  return (
    <div id="School">
      <Helmet>
        <title>
          Imagine. Learn. Create. | Stan Winston School of Character Arts
        </title>
        <meta
          name="description"
          content="Stan Winston School is the world's premiere online destination for learning the art & technology of character creation from Award-winning industry professionals."
        />
        <meta property="og:title" content="Imagine. Learn. Create." />
        <meta
          property="og:description"
          content="Online Instruction with Award-winning Industry Professionals. Wherever you are. On your own time. At your own pace."
        />
      </Helmet>

      <div className="banner-holder-wrapper">
        <button
          aria-label="Show video"
          className="banner-holder"
          onClick={() => setShowVideo(true)}
          onKeyUp={event =>
            keyUpHandler(event, () => setShowVideo(true), null, this)
          }
          tabIndex="0"
          type="button"
        >
          {currentTab === 0 && (
            <picture>
              <source media="(max-width: 799px)" srcSet={SchoolBannerMobile} />
              <source media="(min-width: 800px)" srcSet={SchoolBannerDesktop} />
              <img
                src={SchoolBannerDesktop}
                alt="Stan Winston School of Character Arts"
              />
            </picture>
          )}
          {currentTab === 1 && (
            <picture>
              <source
                media="(max-width: 799px)"
                srcSet={SchoolOurHistoryMobile}
              />
              <source
                media="(min-width: 800px)"
                srcSet={SchoolOurHistoryDesktop}
              />
              <img
                src={SchoolOurHistoryDesktop}
                alt="Stan Winston School of Character Arts"
              />
            </picture>
          )}
          <i className="icon-play" />
        </button>
      </div>

      <VideoModal
        url={videoToDisplay.video}
        visible={showVideo}
        close={() => setShowVideo(false)}
        subtitles={videoToDisplay.subtitles}
      />

      <Tabs onSelect={index => setCurrentTab(index)}>
        <TabList>
          <Tab>
            <span>Our School</span>
          </Tab>
          <Tab>
            <span>Our History</span>
          </Tab>
        </TabList>

        <TabPanel>
          <h1>The Stan Winston School</h1>
          <p>
            Stan Winston School is the world&apos;s premier online destination
            for learning the art and technology of character creation from
            Hollywood&apos;s leading artists and technical wizards. Creating
            compelling characters requires a vast range of skills and
            techniques, from big-budget cinematic approaches to DIY methods you
            can practice in your home workshop. With 1000+ hours of on-demand
            video instruction to choose from, students can subscribe to gain
            instant, anytime access to our entire course library. We also offer
            Live Online Courses, providing students with the opportunity to
            connect with our award-winning faculty in real-time! If you&apos;re
            an artist, creator, or maker who wants to learn from the best,
            welcome to SWSCA. Imagine, Learn, Create!
          </p>
        </TabPanel>

        <TabPanel>
          <p>
            For over 50 years, the name Stan Winston has been synonymous with
            iconic fantasy characters, including the killer cyborgs of The
            TERMINATOR series, the extraterrestrial monstrosities of ALIENS, the
            alien hunter from the PREDATOR series, the prehistoric giants from
            the JURASSIC PARK films, and the iconic suits from IRON MAN. By
            relentlessly pushing the limits of art, technology & imagination,
            Stan Winston Studio continually set new standards of excellence and
            innovation for character creators worldwide. After Stan&apos;s
            untimely passing in 2008, the Winston family founded Stan Winston
            School of Character Arts to preserve Stan&apos;s legacy by inspiring
            and fostering creativity in a new generation of character creators.
            Through connecting the world&apos;s finest artists and technicians
            with students worldwide, our mission is to empower aspiring artists
            and technical wizards to push the boundaries of character creation
            for years to come.
          </p>
        </TabPanel>
      </Tabs>

      <h3>Online instruction with Hollywood&apos;s top artists</h3>

      <div className="awards">
        <div>
          <div className="oscar-holder">
            12 <i className="icon-oscar" />
          </div>
          <h3>Academy Awards</h3>
        </div>
        <div>
          <div className="oscar-holder">
            30 <i className="icon-oscar" />
          </div>
          <h3>Nominations</h3>
        </div>
      </div>

      <div className="artists">
        <div className="artists-holder">
          {artists.map(artist => (
            <Teacher
              className="artist"
              key={`teacher-${artist.slug}`}
              name={artist.fullName}
              photo={artist.photo.boxThumb}
              slug={artist.slug}
            />
          ))}
        </div>
        <Link to="/artists" className="more-artists see-more">
          <i className="icon-plus" /> See More Teachers
        </Link>
      </div>
      <div className="testimonial-holder">
        <TestimonialVideo />
      </div>
    </div>
  )
}

School.propTypes = {
  artists: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      photo: PropTypes.shape({
        boxThumb: PropTypes.string
      }).isRequired,
      intro: PropTypes.string
    })
  ).isRequired,
  testimonial: PropTypes.shape({
    fullName: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,

  /* Dispatchers */
  loadOscarArtists: PropTypes.func.isRequired,
  loadRandomTestimonial: PropTypes.func.isRequired,
  loadRandomArtists: PropTypes.func.isRequired
}

export default connect(stateSelector, dispatchers)(School)
