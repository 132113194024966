import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/testimonials/selectors'
import { testimonialsDispatchers as dispatchers } from 'reduxModules/testimonials/dispatchers'

import VideoModal from '../VideoModal'

import './styles.css'

function Testimonial({ fullName, position, text, thumbnail, videoUrl }) {
  const [isVideoVisible, setVideoVisible] = useState(false)

  if (!thumbnail || !text || !fullName) return null

  return (
    <>
      <section className="testimonial-card">
        <div className="video-thumbnail" onClick={() => setVideoVisible(true)}>
          <button className="play-button" type="button">
            <i className="icon-play" />
          </button>
          <img src={thumbnail} alt="Thumbnail" />
          <div className="testimonial-content">
            <h3>{fullName}</h3>
            <p className="position">{position}</p>
          </div>
        </div>

        <div className="testimonial-quote">
          <p>"{text}"</p>
        </div>
      </section>
      <VideoModal
        close={() => setVideoVisible(false)}
        urlHi={videoUrl?.raw?.high}
        urlLow={videoUrl?.raw?.low}
        subtitles={videoUrl?.subtitles}
        visible={isVideoVisible}
      />
    </>
  )
}

Testimonial.propTypes = {
  fullName: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  videoUrl: PropTypes.shape({
    raw: PropTypes.shape({
      high: PropTypes.string.isRequired,
      low: PropTypes.string.isRequired
    }),
    subtitles: PropTypes.array
  })
}

export default connect(stateSelector, dispatchers)(Testimonial)
