import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/testimonials/selectors'
import { testimonialsDispatchers as dispatchers } from 'reduxModules/testimonials/dispatchers'

import MoreButton from '../MoreButton'
import './styles.css'

export function TestimonialBody({ text, fullName }) {
  return (
    <section id="Testimonial">
      <div className="text">
        <span>
          <i className="icon-quote-open" />
          {text}
          <i className="icon-quote-closed" />
        </span>
        <div className="author">{fullName}</div>
      </div>
    </section>
  )
}

function Testimonial({ text, fullName }) {
  if (!text) return null

  return (
    <section className="testimonial-container">
      <TestimonialBody fullName={fullName} text={text} />
      <div className="see-more-testimonials">
        <MoreButton url="/testimonials" />
      </div>
    </section>
  )
}

Testimonial.propTypes = {
  fullName: PropTypes.string.isRequired,
  text: PropTypes.string
}

Testimonial.defaultProps = {
  email: null,
  text: ''
}

export default Testimonial
