/* eslint-disable import/prefer-default-export */
import { createSelector, createStructuredSelector } from 'reselect'

export const randomTestimonialSelector = state => state.testimonials.random
export const randomVideoTestimonialsSelector = state => state.testimonials.videos
export const testimonialsSelector = state => state.testimonials.list


export const testimonialListSelector = createSelector(
  testimonialsSelector,
  testimonials => ({ testimonials })
)


export default createStructuredSelector({
  random: randomTestimonialSelector,
  videos: randomVideoTestimonialsSelector
})