import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'

import {
  isLoggedInSelector,
  canGiftSubscriptionSelector
} from 'reduxModules/auth/selectors'
import { readyToReviewOrderSelector } from 'reduxModules/checkout/selectors'
import { clientTokenSelector } from 'reduxModules/forms/paymentMethod/selectors'
import {
  isSubscribedReselector,
  isLibrarySubscriberSelector
} from 'reduxModules/users/selectors'

import App from './components/App/index.jsx'
import ArtistList from './components/Artists/ArtistList'
import ArtistDetail from './components/Artists/ArtistDetail'
import LandingPage from './components/LandingPage'
import CourseCategoryLandingPage from './components/CourseCategoryLandingPage'
import Page from './components/Page'
import EmailVerify from './components/EmailVerify'
import Blog from './components/Blog/_lazy'
import Gallery from './components/Gallery/index.jsx'
import Post from './components/Blog/Post/_lazy'
import ForgotPassword from './components/Auth/ForgotPassword'
import ResetPassword from './components/Auth/ResetPassword'
import ChangePassword from './components/Auth/ChangePassword'
import Home from './components/Home'
import Courses from './components/Account/Courses'
import School from './components/School/index.jsx'
import SignUp from './components/Auth/SignUp'
import SignIn from './components/Auth/SignIn'
import PreSignUp from './components/Auth/PreSignUp'
import Contact from './components/Contact'
import Favorites from './components/Account/Favorites/FavoritesList'
import WebinarDetail from './components/Webinars/WebinarDetail/_lazy'
import TutorialList from './components/Tutorials/TutorialList'
import TutorialDetail from './components/Tutorials/TutorialDetail/_lazy'
import Team from './components/Team'
import TeamMember from './components/Team/TeamMember'
import Addresses from './components/Account/Addresses/_lazy'
import PaymentMethods from './components/Account/PaymentMethods'
import NewSearch from './components/NewSearch/index'
import Cart from './components/Cart'
import Checkout from './components/Checkout/_lazy'
import Account from './components/Account'
import CustomerCare from './components/CustomerCare'
import SubscriptionPlans from './components/SubscriptionPlans/index'
import ReviewOrder from './components/ReviewOrder'
import NotFound from './components/Static/NotFound'
import MyAccount from './components/Account/MyAccount/index.jsx'
import WatchHistory from './components/Account/WatchHistory/index.jsx'
import Orders from './components/Account/Orders'
import GiftSubscription from './components/Account/GiftSubscription'
import Interests from './components/Account/Interests'
import Receipt from './components/Receipt'
import ThankYou from './components/ThankYou/index.jsx'
import ChangeSubscription from './components/Account/ChangeSubscription'
import SpecialVideoDetail from './components/SpecialVideos/SpecialVideoDetail'
import SpecialVideoPlayer from './components/SpecialVideos/SpecialVideoPlayer'
import Hijack from './components/Hijack'
import Contest from './components/Contest'
import BookmarkList from './components/Account/BookmarkList'
import LearningPathDetail from './components/LearningPaths/LearningPathDetail'
import LearningPathList from './components/LearningPaths/LearningPathList'
import LearningPaths from './components/Account/LearningPaths'
import LeadMagnetPage from './components/LeadMagnetPage'
import TestimonalList from './components/TestimonialList'
import ProxySSO from './components/ProxySSO'
import OpenAthensSSO from './components/OpenAthensSSO'
import OpenAthensSSOError from './components/OpenAthensSSOError'

import store from './redux/store'

export const requireAuth = (nextState, replace) => {
  const isLoggedIn = isLoggedInSelector(store.getState())
  if (!isLoggedIn)
    replace({
      pathname: '/login',
      query: nextState.location.query,
      state: { next: nextState.location.pathname }
    })
}

export const requireNoAuth = (nextState, replace) => {
  const isLoggedIn = isLoggedInSelector(store.getState())
  if (isLoggedIn)
    replace({
      pathname: '/',
      query: nextState.location.query,
      state: { next: nextState.location.pathname }
    })
}

export const requireOrderReady = (nextState, replace) => {
  const state = store.getState()
  const orderReady = readyToReviewOrderSelector(state)
  const hasClientToken = clientTokenSelector(state) !== ''

  if (!orderReady || !hasClientToken)
    replace({
      pathname: '/checkout',
      state: { next: nextState.location.pathname }
    })
}

export const requireNotSubscribed = (nextState, replace) => {
  const state = store.getState()
  const { isSubscribed } = isSubscribedReselector(state)

  if (isSubscribed)
    replace({
      pathname: '/change_subscription',
      state: { next: nextState.location.pathname }
    })
}

export const requireSubscribed = (nextState, replace) => {
  const state = store.getState()
  const { isSubscribed } = isSubscribedReselector(state)
  const isLoggedIn = isLoggedInSelector(store.getState())

  if (!isSubscribed || !isLoggedIn)
    replace({
      pathname: '/subscriptions/plans',
      state: { next: nextState.location.pathname }
    })
}

export const requireNotLibrarySubscriber = (nextState, replace) => {
  const state = store.getState()
  const isLibrarySubscriber = isLibrarySubscriberSelector(state)

  if (isLibrarySubscriber)
    replace({
      pathname: '/',
      state: { next: nextState.location.pathname }
    })
}

export const requireGiftSubscription = (nextState, replace) => {
  const state = store.getState()
  const canGiftSubscription = canGiftSubscriptionSelector(state)
  if (!canGiftSubscription)
    replace({
      pathname: '/account',
      state: { next: nextState.location.pathname }
    })
}

export default (
  <Route path="/" component={App}>
    <IndexRoute component={Home} />
    <Route path="/artists" component={ArtistList} />
    <Route path="/artists/:artistSlug" component={ArtistDetail} />
    <Route path="/blog" component={Blog} />
    <Route path="/blog/:postSlug" component={Post} />
    <Route path="/stan-winston-school-of-character-arts" component={School} />
    <Route path="/confirm-email" component={EmailVerify} />
    <Route path="/users/password/new" component={ForgotPassword} />
    <Route path="/password/reset/:uid/:token" component={ResetPassword} />
    <Route path="/gift-certificate/:token" component={PreSignUp} />
    <Route
      path="/account/change_password"
      component={ChangePassword}
      onEnter={requireAuth}
    />
    <Route path="/users/signup" component={SignUp} onEnter={requireNoAuth} />
    <Route path="/login" component={SignIn} onEnter={requireNoAuth} />
    <Route path="/landing_pages/:slug" component={LandingPage} />
    <Route path="/lm/:slug" component={LeadMagnetPage} />
    <Redirect from="/webcourses" to="/tutorials" />
    <Redirect from="/videos" to="/tutorials" />
    <Redirect from="/videos/:tutorialSlug" to="/tutorials/:tutorialSlug" />
    <Redirect
      from="/videos/:tutorialSlug/watch"
      to="/tutorials/:tutorialSlug/watch"
    />
    <Route path="/webcourses/:webinarSlug" component={WebinarDetail} />
    <Route
      path="/tutorial_categories/:slug"
      component={CourseCategoryLandingPage}
    />
    <Route path="/tutorials" component={TutorialList} />
    <Route path="/tutorials/:tutorialSlug" component={TutorialDetail} />
    <Redirect
      from="/tutorials/:tutorialSlug/watch"
      to="/tutorials/:tutorialSlug"
      query={{ watch: 1 }}
    />
    <Route path="/gallery" component={Gallery} />
    {/* <Route path="/search" component={Search} /> */}
    <Route path="/search" component={NewSearch} />
    <Route
      path="/cart(/:token)"
      component={Cart}
      onEnter={requireNotLibrarySubscriber}
    />
    <Route
      path="/checkout"
      component={Checkout}
      onEnter={requireNotLibrarySubscriber}
    />
    <Route path="/account" component={Account} onEnter={requireAuth}>
      <IndexRoute component={MyAccount} onEnter={requireAuth} />
      <Route path="/courses" component={Courses} onEnter={requireAuth} />
      <Route path="/favorites" component={Favorites} onEnter={requireAuth} />
      <Route path="/addresses" component={Addresses} onEnter={requireAuth} />
      <Route
        path="/credit_cards"
        component={PaymentMethods}
        onEnter={requireAuth}
      />
      <Route path="/orders" component={Orders} onEnter={requireAuth} />
      <Route
        path="/change_subscription"
        component={ChangeSubscription}
        onEnter={requireSubscribed}
      />
      <Route path="/bookmarks" component={BookmarkList} />
      <Route path="/my_pathways" component={LearningPaths} />
      <Route
        path="/gift_subscription"
        component={GiftSubscription}
        onEnter={requireGiftSubscription}
      />
      <Route path="/interests" component={Interests} />
      <Route path="/history" component={WatchHistory} />
    </Route>
    <Route path="/customer-care" component={CustomerCare}>
      <IndexRoute component={Contact} />
      <Route path="contact-us" component={Contact} />
      <Route path=":slug" component={Page} />
    </Route>
    <Route
      path="/subscriptions/plans"
      component={SubscriptionPlans}
      onEnter={requireNotSubscribed}
    />
    <Route
      path="/subscription/confirmation"
      component={ThankYou}
      onEnter={requireSubscribed}
    />
    <Route
      path="/review_order"
      component={ReviewOrder}
      onEnter={requireOrderReady}
    />
    <Route path="/receipt" component={Receipt} onEnter={requireOrderReady} />
    <Route path="/the-team" component={Team} />
    <Route path="/the-team/:memberSlug" component={TeamMember} />
    <Route
      path="/special-videos/:specialVideoSlug"
      component={SpecialVideoDetail}
    />
    <Route
      path="/special-videos/:specialVideoSlug/watch"
      component={SpecialVideoPlayer}
      onEnter={requireAuth}
    />
    <Route path="/hijack" component={Hijack} onEnter={requireAuth} />
    <Route path="/contest/:slug" component={Contest} />
    <Route path="/pathways/:learningPathSlug" component={LearningPathDetail} />
    <Route path="/pathways" component={LearningPathList} />
    <Route path="/testimonials" component={TestimonalList} />
    <Route path="/contest/:slug/thank-you" component={ThankYou} />
    <Route path="/proxy-sso" component={ProxySSO} />
    <Route path="/open-athens-redirect" component={OpenAthensSSO} />
    <Route
      path="/open-athens-redirect-auth-error"
      component={OpenAthensSSOError}
    />
    <Route path="*" component={NotFound} status={404} />
  </Route>
)
