import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import stateSelector from 'reduxModules/testimonials/selectors'
import { testimonialsDispatchers as dispatchers } from 'reduxModules/testimonials/dispatchers'
import Testimonial from '../Testimonial/index'

import './styles.css'
import TestimonialVideo from './TestimonialVideo'

function TestimonialVideos({ loadRandomTestimonial, random, videos }) {
  useEffect(() => {
    loadRandomTestimonial()
  }, [loadRandomTestimonial])

  return (
    <section id="TestimonialVideos">
      <Testimonial {...random} />
      <div className="testimonial-video-container">
        {videos?.results.map(result => (
          <TestimonialVideo key={result.id} {...result} />
        ))}
      </div>
    </section>
  )
}

TestimonialVideos.propTypes = {
  loadRandomTestimonial: PropTypes.func.isRequired
}

export default connect(stateSelector, dispatchers)(TestimonialVideos)
