/* eslint-disable import/prefer-default-export */
import { testimonialRandomFetch, testimonialsFetch, videoTestimonialRandomFetch } from './'

export const testimonialsDispatchers = dispatch => ({
  loadRandomTestimonial() {
    dispatch(testimonialRandomFetch())
    dispatch(videoTestimonialRandomFetch())
  },
  loadTestimonials() {
    dispatch(testimonialsFetch())
  }
})
